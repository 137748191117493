<template>
  <div class="we-block-type">
    <div class="dropdown">
      <button
        class="btn btn-primary"
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-expanded="false"
      >
        <i class="fas fa-plus mr-2"></i>
        <span>Alan Ekle</span>
      </button>
      <div
        class="dropdown-menu"
        id="we-block-type-dropdown"
        aria-labelledby="dropdownMenuButton"
      >
        <div class="row m-0 justify-content-center align-items-center">
          <button
            class="dropdown-item pl-2"
            v-for="row in localTypes"
            v-bind:key="row.name"
            v-on:click.prevent="createBlock(row)"
          >
            <div
              v-for="col in row.columns"
              v-bind:key="col.name"
              v-bind:class="['pr-0', col.class]"
            >
              <span class="m-0 we-block-type-column w-100"></span>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WeBlockTypes",
  props: {
    value: {
      default: () => [],
    },
  },
  data() {
    return {
      localTypes: [
        {
          id: 1,
          block: {
            title: null,
            backgroundImage: "none",
            backgroundColor: "#ffffff",
            backgroundSize: "100",
            backgroundRepeat: "no-repeat",
            borderWidth: 0,
            borderStyle: "solid",
            borderColor: "#cdcdcd",
            padding: 0,
            borderTopLeftRadius: 6,
            borderTopRightRadius: 6,
            borderBottomLeftRadius: 6,
            borderBottomRightRadius: 6,
            rowGap: 20,
            columnGap: 20,
          },
          columns: [
            {
              type: null,
              content: null,
              class: "col-12",
              style: {
                width: 100,
                backgroundImage: "none",
                backgroundColor: "#ffffff",
                backgroundSize: "100",
                backgroundRepeat: "no-repeat",
                borderWidth: 0,
                borderStyle: "solid",
                borderColor: "#cdcdcd",
                padding: 0,
                borderTopLeftRadius: 6,
                borderTopRightRadius: 6,
                borderBottomLeftRadius: 6,
                borderBottomRightRadius: 6,
                rowGap: 20,
                columnGap: 20,
              },
            },
          ],
        },
        {
          id: 2,
          block: {
            title: null,
            backgroundImage: "none",
            backgroundColor: "#ffffff",
            backgroundSize: "100",
            backgroundRepeat: "no-repeat",
            borderWidth: 0,
            borderStyle: "solid",
            borderColor: "#cdcdcd",
            padding: 0,
            borderTopLeftRadius: 6,
            borderTopRightRadius: 6,
            borderBottomLeftRadius: 6,
            borderBottomRightRadius: 6,
            rowGap: 20,
            columnGap: 20,
          },
          columns: [
            {
              type: null,
              content: null,
              class: "col-6",
              style: {
                width: 50,
                backgroundImage: "none",
                backgroundColor: "#ffffff",
                backgroundSize: "100",
                backgroundRepeat: "no-repeat",
                borderWidth: 0,
                borderStyle: "solid",
                borderColor: "#cdcdcd",
                padding: 0,
                borderTopLeftRadius: 6,
                borderTopRightRadius: 6,
                borderBottomLeftRadius: 6,
                borderBottomRightRadius: 6,
                rowGap: 20,
                columnGap: 20,
              },
            },
            {
              type: null,
              content: null,
              class: "col-6",
              style: {
                width: 50,
                backgroundImage: "none",
                backgroundColor: "#ffffff",
                backgroundSize: "100",
                backgroundRepeat: "no-repeat",
                borderWidth: 0,
                borderStyle: "solid",
                borderColor: "#cdcdcd",
                padding: 0,
                borderTopLeftRadius: 6,
                borderTopRightRadius: 6,
                borderBottomLeftRadius: 6,
                borderBottomRightRadius: 6,
                rowGap: 20,
                columnGap: 20,
              },
            },
          ],
        },
        {
          id: 3,
          block: {
            title: null,
            backgroundImage: "none",
            backgroundColor: "#ffffff",
            backgroundSize: "100",
            backgroundRepeat: "no-repeat",
            borderWidth: 0,
            borderStyle: "solid",
            borderColor: "#cdcdcd",
            padding: 0,
            borderTopLeftRadius: 6,
            borderTopRightRadius: 6,
            borderBottomLeftRadius: 6,
            borderBottomRightRadius: 6,
            rowGap: 20,
            columnGap: 20,
          },
          columns: [
            {
              type: null,
              content: null,
              class: "col-4",
              style: {
                width: 33.3,
                backgroundImage: "none",
                backgroundColor: "#ffffff",
                backgroundSize: "100",
                backgroundRepeat: "no-repeat",
                borderWidth: 0,
                borderStyle: "solid",
                borderColor: "#cdcdcd",
                padding: 0,
                borderTopLeftRadius: 6,
                borderTopRightRadius: 6,
                borderBottomLeftRadius: 6,
                borderBottomRightRadius: 6,
                rowGap: 20,
                columnGap: 20,
              },
            },
            {
              type: null,
              content: null,
              class: "col-4",
              style: {
                width: 33.3,
                backgroundImage: "none",
                backgroundColor: "#ffffff",
                backgroundSize: "100",
                backgroundRepeat: "no-repeat",
                borderWidth: 0,
                borderStyle: "solid",
                borderColor: "#cdcdcd",
                padding: 0,
                borderTopLeftRadius: 6,
                borderTopRightRadius: 6,
                borderBottomLeftRadius: 6,
                borderBottomRightRadius: 6,
                rowGap: 20,
                columnGap: 20,
              },
            },
            {
              type: null,
              content: null,
              class: "col-4",
              style: {
                width: 33.3,
                backgroundImage: "none",
                backgroundColor: "#ffffff",
                backgroundSize: "100",
                backgroundRepeat: "no-repeat",
                borderWidth: 0,
                borderStyle: "solid",
                borderColor: "#cdcdcd",
                padding: 0,
                borderTopLeftRadius: 6,
                borderTopRightRadius: 6,
                borderBottomLeftRadius: 6,
                borderBottomRightRadius: 6,
                rowGap: 20,
                columnGap: 20,
              },
            },
          ],
        },
        {
          id: 4,
          block: {
            title: null,
            backgroundImage: "none",
            backgroundColor: "#ffffff",
            backgroundSize: "100",
            backgroundRepeat: "no-repeat",
            borderWidth: 0,
            borderStyle: "solid",
            borderColor: "#cdcdcd",
            padding: 0,
            borderTopLeftRadius: 6,
            borderTopRightRadius: 6,
            borderBottomLeftRadius: 6,
            borderBottomRightRadius: 6,
            rowGap: 20,
            columnGap: 20,
          },
          columns: [
            {
              type: null,
              content: null,
              class: "col-3",
              style: {
                width: 25,
                backgroundImage: "none",
                backgroundColor: "#ffffff",
                backgroundSize: "100",
                backgroundRepeat: "no-repeat",
                borderWidth: 0,
                borderStyle: "solid",
                borderColor: "#cdcdcd",
                padding: 0,
                borderTopLeftRadius: 6,
                borderTopRightRadius: 6,
                borderBottomLeftRadius: 6,
                borderBottomRightRadius: 6,
                rowGap: 20,
                columnGap: 20,
              },
            },
            {
              type: null,
              content: null,
              class: "col-3",
              style: {
                width: 25,
                backgroundImage: "none",
                backgroundColor: "#ffffff",
                backgroundSize: "100",
                backgroundRepeat: "no-repeat",
                borderWidth: 0,
                borderStyle: "solid",
                borderColor: "#cdcdcd",
                padding: 0,
                borderTopLeftRadius: 6,
                borderTopRightRadius: 6,
                borderBottomLeftRadius: 6,
                borderBottomRightRadius: 6,
                rowGap: 20,
                columnGap: 20,
              },
            },
            {
              type: null,
              content: null,
              class: "col-3",
              style: {
                width: 25,
                backgroundImage: "none",
                backgroundColor: "#ffffff",
                backgroundSize: "100",
                backgroundRepeat: "no-repeat",
                borderWidth: 0,
                borderStyle: "solid",
                borderColor: "#cdcdcd",
                padding: 0,
                borderTopLeftRadius: 6,
                borderTopRightRadius: 6,
                borderBottomLeftRadius: 6,
                borderBottomRightRadius: 6,
                rowGap: 20,
                columnGap: 20,
              },
            },
            {
              type: null,
              content: null,
              class: "col-3",
              style: {
                width: 25,
                backgroundImage: "none",
                backgroundColor: "#ffffff",
                backgroundSize: "100",
                backgroundRepeat: "no-repeat",
                borderWidth: 0,
                borderStyle: "solid",
                borderColor: "#cdcdcd",
                padding: 0,
                borderTopLeftRadius: 6,
                borderTopRightRadius: 6,
                borderBottomLeftRadius: 6,
                borderBottomRightRadius: 6,
                rowGap: 20,
                columnGap: 20,
              },
            },
          ],
        },
      ],
    };
  },
  methods: {
    createBlock(row) {
      const dropdownDom = document.getElementById("we-block-type-dropdown");
      dropdownDom.classList.remove("show");
      this.$emit("create-block", helper.clone(row));
    },
  },
};
</script>

<style lang="scss">
.we-block-type {
  &-column {
    display: block;
    width: 100%;
    height: 40px;
    border: 2px dashed rgb(229, 229, 229);
  }
  .dropdown-item {
    &:hover {
      background-color: rgb(247, 247, 247) !important;
    }
    &:focus {
      outline: none;
    }
  }
}
</style>